import React from 'react';
import { Link } from 'react-router-dom';
import { FaAngleDown } from "react-icons/fa";


const Nav = () => {
    return (
        <nav className="mainmenu-nav">
            <ul className="mainmenu">
                <li className="menu-item-has-children">            
                        <li><Link to={process.env.PUBLIC_URL + "/home-startup"}>Home</Link></li>  
                 
                </li>
                <li className="menu-item-has-children">
                    <Link to="#">Services <FaAngleDown /></Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/service-details/ai-solutions"}>Ai Solutions</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/service-details/digital-marketing"}>Digital Marketing</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/service-details/design"}>Design</Link></li>
                    </ul>
                </li>
                <li><Link to={process.env.PUBLIC_URL + "/why-ai"}>Why Use Ai?</Link></li> 
                <li><Link to={process.env.PUBLIC_URL + "/Our-Ai-Solutions"}>Our Ai Solutions</Link></li>    
                <li><Link to={process.env.PUBLIC_URL + "/contact"}>Contact</Link></li>
            </ul>
        </nav>
    )
}

export default Nav;