import React from 'react';
import {useParams} from 'react-router-dom';
import FooterTwo from '../common/footer/FooterTwo';
import FormThree from '../component/contact/FormThree';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';
import SectionTitle from '../elements/section-title/SectionTitle';
import { slugify } from '../utils';
import ServiceData from "../data/service/ServiceMain.json";
import ProcessOne from '../component/process/ProcessOne';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import Tilty from 'react-tilty';
import SEO from '../common/SEO';

const allServiceData = ServiceData;



const ServiceDetails = () => {

    const params = useParams();
    const serviceSlug = params.slug;

    const getServiceData = allServiceData.filter(data => slugify(data.title) === serviceSlug);
    const detailsService = getServiceData[0];

    return (
        <>
        <SEO title="Service Details" />
        <ColorSwitcher />
        <main className="main-wrapper">
            <HeaderOne />
            <BcrumbBannerTwo 
            title={detailsService.title}
            paragraph ={detailsService.description}
            styleClass=""
            mainThumb={detailsService.thumb}
            />

<div className="section-padding case-study-brief bg-color-mercury">
                    <div className="container">
                        <div className="row align-items-xl-center">
                        <div className="col-lg-6">
                                <div className="case-study-featured-thumb">
                                <Tilty perspective={2000}>
                                    <img src={process.env.PUBLIC_URL + detailsService.image} alt="Case Study" />
                                </Tilty>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-6 offset-xl-1">
                                <div className="case-study-featured">
                                    <div className="section-heading heading-left">
                                        <div dangerouslySetInnerHTML={{__html: detailsService.details}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


           
            <ProcessOne />

            <div className="section-padding bg-color-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-address mb--30">
                            <SectionTitle 
                                subtitle="Ready for lift off?"
                                title="Let’s work together and elevate your business growth to a whole new stratosphere!"
                                description=""
                                textAlignment="heading-light-left"
                                textColor=""
                            />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-form-box mb--30">
                                <h3 className="title">Describe your project</h3>
                                <FormThree />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <FooterTwo />
        </main>
        </>
    )
}

export default ServiceDetails;