import React from 'react';
import {HashRouter as Router, Routes, Route} from 'react-router-dom';
import ScrollToTop from './component/scrollToTop/ScrollToTop';
import 'bootstrap/dist/css/bootstrap.min.css';


// Home Pages Import
import SalesRevolution from './pages/SalesRevolution';
import AiSolutions from './pages/AiSolutions';
import WhyAi from './pages/WhyAi';
import HomeStartup from './pages/HomeStartup';

// Service 
import ServiceDetails from './pages/ServiceDetails';

// Pages 
import Contact from './pages/Contact';
import ErrorPage from './pages/404';


// Css Import
import './assets/scss/app.scss';


const App = () => {
  return (
    <Router>
		<ScrollToTop>
			<Routes>
				<Route path={process.env.PUBLIC_URL + "/"} element={<HomeStartup />}/>

				<Route path={process.env.PUBLIC_URL + "/why-ai"} element={<WhyAi />}/>
				<Route path={process.env.PUBLIC_URL + "/Sales-Revolution"} element={<SalesRevolution />}/>
				<Route path={process.env.PUBLIC_URL + "/Our-Ai-Solutions"} element={<AiSolutions />}/>
				<Route path={process.env.PUBLIC_URL + "/home-startup"} element={<HomeStartup />}/>


				{/* Service */}
				<Route path={process.env.PUBLIC_URL + "/service-details/:slug"} element={<ServiceDetails />}/>


				{/* Pages  */}
				<Route path={process.env.PUBLIC_URL + "/contact"} element={<Contact />}/>
				<Route path={process.env.PUBLIC_URL + "/404"} element={<ErrorPage />}/>


			</Routes>
		</ScrollToTop>
    </Router>
  )
}

export default App;
