import React from 'react';
import FooterTwo from '../common/footer/FooterTwo';
import HeaderOne from '../common/header/HeaderOne';
import BcrumbBannerTwo from '../elements/breadcrumb/BcrumbBannerTwo';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';


const HomeStartup = () => {

    return (
        <>
        <SEO title="Home Startup"/>
        <ColorSwitcher />
        <main className="main-wrapper">
        <HeaderOne />
        <BcrumbBannerTwo 
            title="Examples of our insurance sector AI Automations"
            styleClass=""
            mainThumb="/images/banner/24.png"
            />



                <div className="section-padding">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">

                            <p className="body-font1">AI technology also offers scalability and speedy deployment once a successful approach is identified. The following is a list of just some of the services we can provide. Just one or as many of these services can be set up for you all easily managed within a custom CRM system.</p>


                                <h4>Next Generation Ai Assitants</h4>

                                <p className="body-font1">How they work:</p>

                                <blockquote className="wp-block-quote">
                                <p>Trained on over 100,000 hours of real life support staff coversations, tailored for insurance sector our highly advanced chatbots can answer questions and provide detailed automated responses that mimic human interaction. Our superhuman AI assitants are ready to be trained on your compny data, alongside FAC compliance handbook and a myriad of insurace sales FAQs. This enables our clients to talk with their documents, handbook, website etc, and provide 24 hour support</p>
                                </blockquote>



                                <h4>Resume Analyzer for Hiring</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>Our Resume Analyzer is your Hiring and Recruiting assistant. This AI automation is designed to review and assess all submitted resumes to find you the best applicant for your business. Get a strong recommendation and detailed reporting for your next new hire.</p>
                                </blockquote>

                            

                                <h4>AI Lead Generation, Outreach and Onboarding Solution</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>A solution that saves countless hours and cuts costs by automatically performing lead generation through various channels and qualifies them using AI to ensure the highest conersion rates, with automated outreach and pipeline management for onboarding.</p>
                                </blockquote>    



                                <h4>Linkedin Lead Generation</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>With this automation you will be able to scrape Linkedin Profiles, get their email and use AI to automatically create a personalized cold reach email and send it to your lead.</p>
                                </blockquote>    


                                <h4>Agency Onboarding Bot</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>With the Agency Onboarding Bot you will able to analyze a onboarding form for your client, and AI will let you know what your onboarding process is missing or how you can improve, it will also summarize all your client answers.</p>
                                </blockquote>                                 



                                <h4>Zoom Recording Organizer</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>Zoom recording organizer, record your zoom calls, and transcribe them using AI and after that get a google docs with a summary about the call.</p>
                                </blockquote>  


                                <h4>Support Analysis Report</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>With this automation you will be able to retrieve all the emails from your support email, store the support feedback in an airtable database, and use AI to analyze all your user feedback to provide you with an analysis of what you can improve on your business.</p>
                                </blockquote>    



                                <h4>AI Survey Sentiment Analysis</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>With this automation you will be able to use AI to analyze the sentiment of one of your surveys, we will use google sheets for this example but you can use other services as typeform.</p>
                                </blockquote>     



                                <h4>Web Scraper Bot</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>Designed as research tool, Our Web Scraper Bot can source the internet and pull data from any topic into a google sheet. You are then able to ask questions based on the topic via Ai Assistant </p>
                                </blockquote>

                                

                                <h4>SEO Site Analysis Tool</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>SEO Site Analysis Tool, With this automation you will be able to analyze your site SEO using AI</p>
                                </blockquote>




                                <h4>Twitter Posting System</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>Twitter Posting system , using this solution you will be able to automate your Twitter account to post tweets automatically using AI.</p>
                                </blockquote>                                                               



                                <h4>Instagram Auto Commenting</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>With this solution you will be able to use AI to respond automatically to all the comment you get in your posts.</p>
                                </blockquote>          




                                <h4>Facebook Auto Commenting</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>With this solution you will be able automate your Facebook posts for a page you manage using AI.</p>
                                </blockquote>               




                                <h4>Facebook Post Filtering</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>With this solution you will be able to use AI to monitor Your facebook pages comments and AI will automatically detect cyberbullying, hate speech, profanities, etc. and will delete that comment.</p>
                                </blockquote>        

    

                                <h4>Email Auto Responder</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>With this automation you will be able to use AI to respond to all your incoming emails.</p>
                                </blockquote>          



                                <h4>Custom Email Auto Responder</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>With this AI you will be able to connect a custom trained chatbot to your email response automation to auto respond to emails.</p>
                                </blockquote>          



                                <h4>AI Email Filtering Automation</h4>

                                <p className="body-font1">How it works:</p>

                                <blockquote className="wp-block-quote">
                                <p>With this bot you will be able to use AI to automatically label all your incoming emails so they are better organized.</p>
                                </blockquote>          
     

                                <p className="body-font1"><a href="/Sales-Revolution">MUST READ: How we are revolutionizing sales in the insurance sector </a></p>



                            </div>
                        </div>
                    </div>
                </div>

        <FooterTwo />
        </main>
        </>
    )
}

export default HomeStartup;

