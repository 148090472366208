import React from 'react';
import SEO from '../common/SEO';
import ColorSwitcher from '../elements/switcher/ColorSwitcher';
import FooterTwo from '../common/footer/FooterTwo';
import FormThree from '../component/contact/FormThree';
import HeaderOne from '../common/header/HeaderOne';
import BreadCrumbOne from '../elements/breadcrumb/BreadCrumbOne';
import SectionTitle from '../elements/section-title/SectionTitle';




const Contact = () => {
    return (
        <>
            <SEO title="Blog Grid" />
            <ColorSwitcher />
            <main className="main-wrapper">
                <HeaderOne />
                <BreadCrumbOne 
                title="Contact"
                page="Contact"
                />

<div className="section-padding bg-color-dark">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="contact-address mb--30">
                            <SectionTitle 
                                subtitle="Ready for lift off?"
                                title="Let’s work together and elevate your business growth to a whole new stratosphere!"
                                description=""
                                textAlignment="heading-light-left"
                                textColor=""
                            />
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="contact-form-box mb--30">
                                <h3 className="title">Describe your project</h3>
                                <FormThree />
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <FooterTwo />

            </main>
        </>
    )
}

export default Contact;